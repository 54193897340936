

import React, {useEffect, useState} from "react"
import styled from'styled-components'

import {useSelector, useDispatch} from 'react-redux'
import { fetchLoans, fetchBalanceSheet } from "../Features/api/api"
import { Link, Outlet, useNavigate, useLocation, Navigate } from "react-router-dom"
import LoanForm from "./LoanForm"
import LoanPaymentForm from "./LoanPaymentForm"
import { removeTimeFromDateString } from "../SharedFns/Functions"
import Spinner from "../SharedFns/SpinnerElement"
import Modal from "../SharedFns/Modal"

const LoanList= ()=>{
 
    const [isModalOpen, setIsModalOpen ]=useState(false)
    const dispatch= useDispatch()
    const navigate=useNavigate()
    const location=useLocation()
    const currentDate= new Date()
    const formattedDate= `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`

    useEffect(()=>

    {dispatch(fetchLoans())
      dispatch(fetchBalanceSheet(formattedDate))
    },  [dispatch])
    const loans= useSelector( state => state.getLoans.loans?.loans)
    const isLoading =useSelector( state=> state.getLoans?.loading || state.getbalanceSheet?.loading)
    const balanceSheetData = useSelector(state => state.getbalanceSheet?.balanceSheetData.balance_sheet??[]) 
    const interestReceivables=balanceSheetData.assets?.interest_receivable.balance
    const bankBalance= (balanceSheetData.assets?.bank.balance)  + (interestReceivables) 
    const filteredLoans=loans?.slice().sort((a,b)=>{
      return new Date(b.start_date)- new Date(a.start_date)
    })
    const HandlePayLoanClick= (LoanId)=>{
      navigate(`repay-loan/${LoanId}`);
      console.log(LoanId)
    }
    const HandleLoanApplication=()=>{
      setIsModalOpen(true)

    }
    const aprovalCodeToName={

      'pending_approval': 'PENDING APPROVAL',
      'rejected':'REJECTED',
      'approved':'APPROVED',
      'pending':'PENDING',
      'cleared':'CLEARED',
      'PENDING_APPROVAL':'PENDING APPROVAL',
      'emergency_loan':'Emergency Loan',
      'normal_loan':'Normal Loan',
      'active':'ACTIVE',
      'default':'DEFAULT'
  }
    return(
       
        // renderContent()
        <Container>
          <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)} >
               <LoanForm setIsModalOpen={setIsModalOpen} bankBalance={bankBalance}/>
          </Modal>
          <div>
            {isLoading && <Spinner/>}
          </div>
          <TopBar>
          <h2>Your Loans</h2>
          <Button onClick={HandleLoanApplication} >
           Apply New Loan
          </Button>
          
           </TopBar> 
         
          <Table>
             <thead>
                 <tr>
                 <TableHeader>Loan Name</TableHeader>
             <TableHeader className="hide-on-small-screen">Issue Date</TableHeader>
             <TableHeader>Expiry Date</TableHeader>
             <TableHeader>Loan Type</TableHeader>
             <TableHeader>Principal Amout </TableHeader>
             <TableHeader>Approval Status</TableHeader>
 
             <TableHeader> Loan Status</TableHeader>
             <TableHeader>Remaining Balance</TableHeader>
                 </tr>
             </thead>
             <tbody>
                 {filteredLoans?.map(
                  loan=>(
                     <tr key={loan.loan_id}>
                         <TableCell>{loan.loan_name}</TableCell>
               <TableCell className="hide-on-small-screen">{removeTimeFromDateString(loan.start_date)}</TableCell>
               <TableCell>{removeTimeFromDateString(loan.expiry_date)}</TableCell>
               <TableCell>{aprovalCodeToName[loan.loan_type]}</TableCell>
               <TableCell>{loan.amount}</TableCell>
               <TableCell>{aprovalCodeToName[loan.loan_approval]}</TableCell>
               <TableCell> {aprovalCodeToName[loan.status]}</TableCell>
               <TableCell> {loan.current_balance}</TableCell>
               <TableCell>
                <Button disabled= {loan.status =='cleared' || loan.status =='pending'}onClick={()=>HandlePayLoanClick(loan.loan_id)}>
                  Pay now
                </Button>
               </TableCell>
                     </tr>
                  )    
                 )}
             </tbody>
          </Table>
      
         </Container>
        )
       
                 }
const Container=styled.div`
width:100%;
height:100%;
overflow-y:scroll;
 /* Hide the vertical scrollbar for webkit-based browsers */
 ::-webkit-scrollbar {
    width: 0.5em;
  }

  /* Track (the area where the scrollbar is) for webkit-based browsers */
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  /* Handle (the draggable part of the scrollbar) for webkit-based browsers */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover for webkit-based browsers */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Hide the vertical scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  /* Optional: Add padding to the content area to prevent overlap with scrollbar */
  padding-right: 0.5em;
  @media (max-width:768px) {
  overflow-x:scroll;
  
}
`                 
const Table=styled.table`
width:100%;

border-collapse:collapse;
margin-bottom: 100px;
@media (max-width:768px) {
  width: 50%; 
  font-size:10px;
  margin-right: 50px;
   
  
  overflow-x: auto; /* Enable horizontal scrolling */
  
}
`
const TableHeader=styled.th`

border: 1px solid #000;
padding:10px;
text-align: left;
background-color: #f2f2f2;
@media (max-width:768px) {
  width:12px;
  font-size:10px;
  &.hide-on-small-screen {
    display: none; // hide this column on small screens
  }
  
}

` 
const TableCell = styled.td`
  border: 1px solid #000;
  padding: 10px;
  text-align: left;
  @media (max-width:768px) {
    width: 8px;
    height: 10%;
    font-size: 10px;
  &.hide-on-small-screen {
    display: none; // hide this column on small screens
  }
  
}
`
const TopBar=styled.div`
width:100%;
height:50px;
display:flex;
flex-direction:row;
align-items:center;
justify-content: space-around;
@media (max-width: 768px) {
  h2{
    font-size:15px;
  }
  
}
`
const Linkto=styled(Link)`
cursor:pointer;
align-items:center;
margin-right:100px;
text-decoration:none;
display:flex;
justify-content:flex-start;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color:#E2725B;
color:#f9f9f9;
border:none;
border-color:#E2725B;
`
const Button=styled.button`
cursor:pointer;
align-items:center;
margin-left:20px;
text-decoration:none;
display:flex;
justify-content:flex-start;
cursor: ${ props=> props.disabled? 'arrow': 'pointer'};
padding: 10px 30px;
border-radius:10px;
background-color: ${props => props.disabled ? '#ccc' : '#E2725B'};
color:#f9f9f9;
border:none;
border-color:#E2725B;
`
export default LoanList