import { useState } from 'react'
import styled from 'styled-components'
import { applyLoan } from '../Features/api/api'
import { useDispatch } from 'react-redux'
const LoanForm=({bankBalance, setIsModalOpen})=>{
    const dispatch=useDispatch()
    const [error, setError]=useState('')
    const [loanData, setLoanData]=useState({
     
        amount:'',
        loan_type:''
    }
       
    )
    const handleOnchange= (e)=>{
        const{name, value}= e.target
        setLoanData({
            ...loanData, [name]: value}
        )
        setError('')
    }
    const handleSubmit= async (e)=>{
        e.preventDefault()
        const loanAmount = parseFloat(loanData.amount);

        // Validate the amount
        if (loanAmount > bankBalance) {
            setError('Amount exceeds the current bank balance.');
            return;
        }
        dispatch(applyLoan(loanData))
        setIsModalOpen(false)
       
    }
    return(
        <Container>
            <h1>Apply Loan below</h1>
            <p> Please  note that loans exceeding your loan limit will automatically be rejected.   </p>
         <StyledForm onSubmit={handleSubmit}>
        
         <FormOuterRow>
                    {/* <Form_Row>
                        <label htmlFor="loan_name" >
                            Loan name
                        </label>
                        <input name="loan_name"
                            id="loan_name"
                            type="text"   placeholder="Loan name: e.g Loan 1" onChange={handleOnchange}required />
                        

                    </Form_Row> */}
                    <Form_Row>
                        <label htmlFor="Amount" >
                            Amount:
                        </label>
                        <input name="amount"
                            id="amount"
                            type="Number"   placeholder="Amount" onChange={handleOnchange}required />
                        
                        {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
                    </Form_Row>
                    <Form_Row>
                        <label htmlFor="loan_type" >
                            Loan Type:
                        </label>
                        <select name="loan_type"  id="loan_type"
                            type="text"   placeholder="Loan type: e.g, emergency loan" onChange={handleOnchange}required>
                                <option value="">Select</option>
                               <option value="normal_loan">Normal Loan</option>
                                 <option value="emergency_loan">Emergency Loan</option>

                            </select>
                        

                    </Form_Row>
          </FormOuterRow>      
       <AuthButtons>
       <SignUpBtn type="submit" > submit</SignUpBtn>
       </AuthButtons>
        
       
         
         
         </StyledForm>
        </Container>
    )
}

const Container=styled.div`
width:100%;
height:100%;

display:flex;
flex-direction:column;


justify-content:flex-start;
h1{
    display:flex;
    justify-content:flex-start;
    margin-left:0px;
}
p{
    display:flex;
    justify-content:flex-start;
   

}
@media (max-width: 768px) {
    overflow-y:scroll;
    height:100vh;
    h2{
        font-size:12px;
    }
    p{
        font-size:12px;
    }
}
`
const FormOuterRow=styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
margin-bottom:10px;
`
const Form_Row = styled.div`

margin-bottom:10px;
margin-right:5px;
display:flex;
flex-direction:column;
justify-content:center;
width:100%;

input {
  border: none; /* Remove default border */
  border-bottom: 1px solid #353935; /* Add a single line at the bottom */
  outline: none; /* Remove outline when focused */
  width:100%; 
  
}
select {
  margin-top: 10px;  
  border: none; /* Remove default border */
  border-bottom: 1px solid #353935; /* Add a single line at the bottom */
  outline: none; /* Remove outline when focused */
  width:100%; 
  
}
label{
    font-size:12px;
}
@media (max-width: 768px) {
    .label{
        font-size:8px;
    }
    
}
`
const StyledForm=styled.form`
margin-left:0px;
display:flex;
flex-direction:column;
justify-content:flex-start;
width:80%;

overflow-y:auto;
@media (max-width: 768px) {
    // overflow-y:scroll;
}
`
const AuthButtons = styled.div`
display: flex;
align-items:center;
flex-direction:row;
justify-content:center;
margin-top:20px;
width:100%;
padding-left: 0px;
margin-left:0px;
`
const SignUpBtn = styled.button`
margin-left:0px;
display:flex;
justify-content:center;
cursor:pointer;
padding: 10px 30px;
border-radius:10px;
background-color: #E2725B;
/* background-color:red; */
color:#f9f9f9;
border:none;
width:50%;
border-color:#E2725B;
@media (max-width: 768px) {
    font-size:10px;
    padding: 5px 15px;
}
`
export default LoanForm